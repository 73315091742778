/*export class PreseModel {
    constructor(
        public id: number,
        public isactive: string,
        public rowversion: string,
        public id_an_type_registry: number,
        public birthdate: string| null,
        public id_an_gender: number,
        public description: string,
        public picture: string,
        public firstname: string,
        public surname: string,
        public id_an_city: number,
        public id_an_country: number,
        public mail: string,
        public phonenumber: string,
        public street: string
    ){}
    static getDefault = () : PreseModel=> {
        return (new PreseModel(-1,'Y','',-1,'',-1,'','','','',-1,-1,'','',''))
    }
}*/

export class PreventivoPortDTO {
    constructor(
        public dataScadenza: string,
        public scaduto: boolean,
        public tipoCliente: string,
        public presa: PresaPortDTO,
        public idPresa: number
    ){}
}

export class PresaPortDTO {
    constructor(
        public id: number,
        public idBolla: number,
        public esercizio: number,
        public numeroPresa: number,
        public stato: number,
        //public codiceSoggetto: string,
        //public soggetto: SoggettoMetodo | undefined,
        public mittente: MittentePortDTO,
        public destinazioni: DestinazionePortDTO[],
        public operatore: string,
        public riferimentoFatturazione: string,
        public dataRitiro: string,
        public oraRitiro: string | undefined,
        public pronto: boolean,
        public flagMercePericolosa: boolean,
        public flagPreavvisoTelefonico: boolean,
        public flagSpondaIdraulica: boolean,
        public noteRitiro: string,
        public autista: string,
        public targa: string,
        public operatorePortale: string,
        public noteClienteNoPortale: string,
        public soggettoPreventivo: PresaPortSoggettoPreventivoDTO
    ) { }
    static getDefault = (): PresaPortDTO => {
        return (new PresaPortDTO(0, 0, 0, 0, -1,/*'',undefined,*/MittentePortDTO.getDefault(), [DestinazionePortDTO.getDefault()], '', '', '', '', false, false, false, false, '', '', '', '', '',PresaPortSoggettoPreventivoDTO.getDefault()))
    }
}

export class PresaPortSoggettoPreventivoDTO {
    constructor(
        public RagioneSociale :string,
        public Indirizzo :string,
        public Localita :string,
        public CAP :string,
        public Provincia :string,
        public CodiceNazione :string,
        public Nome :string,
        public Telefono :string, 
        public CodiceFiscale :string,
        public PartitaIva :string,
    ) { }
    static getDefault = (): PresaPortSoggettoPreventivoDTO => {
        return (new PresaPortSoggettoPreventivoDTO('','','','','','','','','',''))
    }
}

/*export class SoggettoMetodo{
    constructor(
        public TipoSoggettoRicerca: number, //NonImpostato = 0,Cliente = 1,Fornitore = 2,AnagraficaLibera = 3
        public NoteSoggetto:string,
        public Telefono:string,
        public Nome:string,
        public Isola: boolean,
        public ZonaDisagiata: boolean,
        public Zona:string,
        public Regione:string,
        public Nazione:string,
        public CodiceNazione:string,
        public Provincia:string,
        public CAP:string,
        public Localita:string,
        public Indirizzo:string,
        public PerContoDi:string,
        public RagioneSociale:string,
        public Codice:string,
        public FlagObbligoRiferimentoFatturazione: boolean,
        public FlagObbligoRiferimentoRitiro: boolean,
    ){}
}*/

export class MittentePortDTO {
    constructor(
        public id: number,
        public ragioneSociale: string,
        public perContoDi: string,
        public indirizzo: string,
        public cap: string,
        public localita: string,
        public provincia: string,
        public codiceNazione: string,
        public nazione: string,
        public flagZonaDisagiata: boolean,
        public flagIsola: boolean,
        public nome: string,
        public telefono: string,
        public mail: string,
        public riferimentoRitiro: string,
        public orarioApertura1: string,
        public orarioChiusura1: string,
        public orarioApertura2: string | null,
        public orarioChiusura2: string | null,
        public orarioContinuato: boolean
    ) { }
    static getDefault = (): MittentePortDTO => {
        return (new MittentePortDTO(0, '', '', '', '', '', '', '', '', false, false, '', '', '', '', '', '', '', '', false))
    }
}

export class DestinazionePortDTO {
    constructor(
        public id: number,
        public ragioneSociale: string,
        public indirizzo: string,
        public localita: string,
        public provincia: string,
        public cap: string,
        public codiceNazione: string,
        public nazione: string,
        public imballi: ImballoPortDTO[],
        public personaRiferimento: string,
        public telefono: string,
        public flagMercePericolosa: boolean,
        public flagPreavvisoTelefonico: boolean,
        public flagSpondaIdraulica: boolean,
        public extraSagoma: boolean,
        public perContoDi: string,
        public noteConsegna: string,
        public pesoTotale: number | undefined,
        public flagMerceNonSovrapponibile: boolean,
        public volumeTotale: number,
        public codiceNazioneFinale: string | undefined,
        public nazioneFinale: string | undefined,
        public destinazioneFinale: string | undefined
    ) { }
    static getDefault = (): DestinazionePortDTO => {
        return (new DestinazionePortDTO(0, '', '', '', '', '', '', '', [], '', '', false, false, false, false, '', '', undefined, false, 0, undefined, undefined, undefined))
    }
}

export class ImballoPortDTO {
    constructor(
        public id: number,
        public descrizione: string,
        public lunghezza: number | undefined,
        public larghezza: number | undefined,
        public altezza: number | undefined,
        public nrPallet: number | undefined,
        public nrColli: number | undefined,
        public peso: number | undefined
    ) { }
    static getDefault = (): ImballoPortDTO => {
        return (new ImballoPortDTO(0, '', undefined, undefined, undefined, undefined, undefined, undefined))
    }
}




export class PresaListModel {
    constructor(
        public id: number,
        public numeroPresa: number,
        public numeroBolla: number,
        public dataPresa: string, //datetime
        public operatore: string,
        public dataRitiro: string, //object
        public stato: number,
        public statoimg: string,
        public oraRitiro: string,
        public Volume: number,
        public Peso: number,
        public colliTotale: number,
        public palletTotale: number,
        public quantita: number,
        public codiceLineaRitiro: string,
        public lineaRitiro: string,
        public autista: string,
        public veicolo: string,
        public cdsofa: string,
        public RagioneSocialeCdSofa: string,
        public mittenteRagioneSociale: string,
        public mittenteCAP: string,
        public mittenteLocalita: string,
        public mittenteProvincia: string,
        public LocalitaMittente: string,
        public destinazioneRagioneSociale: string,
        public destinazioneCAP: string,
        public destinazioneLocalita: string,
        public destinazioneProvincia: string,
        public destinazioneCodNazione: string,
        public destinazioneNazione: string,
        public destinazioneCodiceLinea: string,
        public destinazioneRagioneSocialeLinea: string,
        public codiceLista: string,
        public idBolla_passaggioAVuoto: string, //object
        public RagioneSocialeDest: string,
        public numDestinazioni: number,
        public destinazioneFinale: string,
        public flagInviaMailLinea: number,
        public flagStatoMailLinea: string,
        public flagPresaRapida: number,
        public operatoreModifica: string,
        public dataModifica: string, //datetime
        public daPortale: boolean
    ) { }
}

export const PreseSearchModelFilterStateDefault = 60;

export class PreseSearchModel {
    constructor(
        public nrPresa: number | undefined,
        public esercizio: number | undefined,
        public daData: string | undefined,
        public aData: string | undefined,
        public stato: number | undefined,
        public codiceMittente: string | undefined,
        public ragioneSocialeMittente: string | undefined,
        public codiceDest: string | undefined,
        public ragioneSocialeDest: string | undefined,
        public pageNr: number,
        public pageSize: number,
        public sortOrder: string | undefined,
        public sortDirection: string | undefined,
        public soloDaPortale: boolean,
        public codiceCliente: string | undefined
    ) { }
    static getDefault = (): PreseSearchModel => {
        return (new PreseSearchModel(undefined, undefined, undefined, undefined, PreseSearchModelFilterStateDefault /*lo stato "caricata", di default facciamo vedere quelli e non tutti */, undefined, undefined, undefined, undefined, 1, 10, undefined, undefined, true, undefined))
    }
}

export const getPreseImballiRowType = (record: any): { type: 'pallet' | 'colli' | undefined, number: Number } => {
    let defaultValue: ('pallet' | 'colli' | undefined) = undefined;
    let numberGeneric = undefined;

    if (record.nrPallet !== undefined && record.nrPallet > 0) {
        defaultValue = 'pallet';
        numberGeneric = record.nrPallet;
    } else {
        if (record.nrColli !== undefined && record.nrColli > 0) {
            defaultValue = 'colli';
            numberGeneric = record.nrColli;
        }
    }

    //altrimenti lasciamo vuoto e lo devono selezionare

    return { type: defaultValue, number: numberGeneric };
}



