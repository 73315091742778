import { TranslateString } from "../../i18n/translate";
import { DestinazionePortDTO, PresaPortDTO, PreventivoPortDTO } from "../Model/PreseModel"
import { Collapse, Descriptions, Tag } from "antd";
import { Table, Tabs } from "antd";

import {
    CheckSquareOutlined, BorderOutlined,WarningOutlined
} from '@ant-design/icons';
import { FormTextInput_new } from "../Utils_Component/InputForm";
import React, { useEffect, useMemo } from "react";
const { Panel } = Collapse;
interface PreventiviHeadSummaryProps {
    record: PreventivoPortDTO,
    onChange: (record: PresaPortDTO) => void,
}


export const PreventiviHeadSummary: React.FC<PreventiviHeadSummaryProps> = props => {
    const [originalRecord, setOriginalRecord] = React.useState<PreventivoPortDTO | undefined>(undefined);

    useEffect(() => {
        if (originalRecord === undefined) {
            setOriginalRecord(props.record);
        }
    }, []);

    const mittente = props.record.presa.mittente;
    const destinatario = props.record.presa.destinazioni[0];

    const columnsProducts = [
        {
            title: 'Descrizione',
            dataIndex: 'descrizione',
            key: 'descrizione'
        },
        {
            title: 'Lung.',
            dataIndex: 'lunghezza',
            key: 'lunghezza'
        },
        {
            title: 'Larg.',
            dataIndex: 'larghezza',
            key: 'larghezza'
        },
        {
            title: 'Alt.',
            dataIndex: 'altezza',
            key: 'altezza'
        },
        {
            title: 'Pallet',
            dataIndex: 'nrPallet',
            key: 'nrPallet'
        },
        {
            title: 'Colli',
            dataIndex: 'nrColli',
            key: 'nrColli'
        },
        {
            title: 'Peso',
            dataIndex: 'peso',
            key: 'peso'
        },
        {
            title: 'Extra Sagoma',
            dataIndex: 'extraSagoma',
            key: 'extraSagoma'
        },
    ];

    const handleChangesMittentePortDTO = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {//gestisce i cambiamenti di stato che avvengono nelle textbox della form
        const target = event.target;
        const value = target.value;//contiene il nuovo valore della textbox
        const name = target.name;//contiene il nome del valore così potrà essere cambiato autonomamente, come si vede nel passaggio sottostante con [name]
        const newRecord = {
            ...props.record.presa,
            mittente: {
                ...props.record.presa.mittente,
                [name]: value
            }
        };

        //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
        props.onChange(newRecord);
    }

    const handleChangesDestinatarioPortDTO = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {//gestisce i cambiamenti di stato che avvengono nelle textbox della form

        let destionazioneNew = props.record.presa.destinazioni[0];

        //let newDestinazioni = props.record.presa.destinazioni.filter(p => p.id != updated.id);

        //newDestinazioni.unshift(updated); //push element at 0

        const target = event.target;
        const value = target.value;//contiene il nuovo valore della textbox
        const name = target.name;//contiene il nome del valore così potrà essere cambiato autonomamente, come si vede nel passaggio sottostante con [name]

        const newRecord = {
            ...props.record.presa,
            destinazioni: [
                {
                    ...props.record.presa.destinazioni[0],
                    [name]: value
                }
            ]
        };

        //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
        props.onChange(newRecord);
    }


    const alertDatiMancanti = useMemo(() => {
        return true;
    }, []);


    return <Collapse style={{ marginTop: 10, marginBottom: 10 }} >
        <Panel header="Dati Preventivo" extra={alertDatiMancanti ? <Tag color="error">Dati incompleti <WarningOutlined /></Tag>: <></>} key="1">
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <div style={{ width: 'calc(50% - 10px)' }}>
                    <div className="ant-descriptions-title">Mittente</div>
                    <Descriptions bordered column={2} >
                        <Descriptions.Item label={TranslateString("@RAGSOC")} span={2}>

                            <FormTextInput_new
                                onChange={handleChangesMittentePortDTO}
                                //label_name="RAGSOC"
                                mandatory={true}
                                input_name={"ragioneSociale"}
                                input_value={props.record.presa.mittente.ragioneSociale!}
                                validate={undefined}
                                readonly={originalRecord?.presa.mittente.ragioneSociale !== ''}
                                hideLabel
                            //onKeyPress={handleSearchMittente}
                            />

                        </Descriptions.Item>
                        <Descriptions.Item label={TranslateString("@INDIRIZZO")}>
                            <FormTextInput_new
                                onChange={handleChangesMittentePortDTO}
                                //label_name="RAGSOC"
                                mandatory={true}
                                input_name={"indirizzo"}
                                input_value={props.record.presa.mittente.indirizzo!}
                                validate={undefined}
                                readonly={originalRecord?.presa.mittente.indirizzo !== ''}
                                hideLabel
                            //onKeyPress={handleSearchMittente}
                            />
                        </Descriptions.Item>
                        <Descriptions.Item label={'Provincia'}>{mittente.provincia}</Descriptions.Item>
                        <Descriptions.Item label={'Località'}>{mittente.localita}</Descriptions.Item>
                        <Descriptions.Item label={'Nazione'}>{mittente.nazione}</Descriptions.Item>

                    </Descriptions>
                </div>
                <div style={{ width: 'calc(50% - 10px)' }}>
                    <div className="ant-descriptions-title">Destinatario</div>
                    <Descriptions bordered column={2} >
                        <Descriptions.Item label={TranslateString("@RAGSOC")} span={2}>

                            <FormTextInput_new
                                onChange={handleChangesDestinatarioPortDTO}
                                //label_name="RAGSOC"
                                mandatory={true}
                                input_name={"ragioneSociale"}
                                input_value={props.record.presa.destinazioni[0].ragioneSociale!}
                                validate={undefined}
                                readonly={originalRecord?.presa.destinazioni[0].ragioneSociale !== ''}
                                hideLabel
                            //onKeyPress={handleSearchMittente}
                            />
                        </Descriptions.Item>
                        <Descriptions.Item label={TranslateString("@INDIRIZZO")}>

                            <FormTextInput_new
                                onChange={handleChangesDestinatarioPortDTO}
                                //label_name="RAGSOC"
                                mandatory={true}
                                input_name={"indirizzo"}
                                input_value={props.record.presa.destinazioni[0].indirizzo!}
                                validate={undefined}
                                readonly={originalRecord?.presa.destinazioni[0].indirizzo !== ''}
                                hideLabel
                            //onKeyPress={handleSearchMittente}
                            />
                        </Descriptions.Item>
                        <Descriptions.Item label={'Provincia'}>{destinatario.provincia}</Descriptions.Item>
                        <Descriptions.Item label={'Località'}>{destinatario.localita}</Descriptions.Item>
                        <Descriptions.Item label={'Nazione'}>{destinatario.nazione}</Descriptions.Item>
                    </Descriptions>
                </div>
            </div>
            <Descriptions bordered column={3} style={{ marginTop: 10, marginBottom: 20 }}>
                <Descriptions.Item label={'Preavviso Telefonico'} >{destinatario.flagPreavvisoTelefonico ? <CheckSquareOutlined /> : <BorderOutlined />}</Descriptions.Item>
                <Descriptions.Item label={'Sponda Idraulica'}>{destinatario.flagSpondaIdraulica ? <CheckSquareOutlined /> : <BorderOutlined />}</Descriptions.Item>
                <Descriptions.Item label={'Merce Non Sovrapponibile'}>{destinatario.flagMerceNonSovrapponibile ? <CheckSquareOutlined /> : <BorderOutlined />}</Descriptions.Item>
            </Descriptions>
            <div className="ant-descriptions-title">Merce</div>
            <Table columns={columnsProducts} dataSource={destinatario.imballi} pagination={false} scroll={{ y: 600 }} />

        </Panel>
    </Collapse>
}