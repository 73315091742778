import React from "react";
import { PresaPortDTO } from "../Model/PreseModel"
import { RestCall } from "../Rest/Rest_Call/RestCall";
import { TFestivi } from "../Model/Festivi";
import RestUrl from '../../RestUrl.json';
import { notifyError } from "../CustomToast/customToast";
import { FormDataPickerInput, FormTextInput_new, checkStringOnlyNumbers } from "../Utils_Component/InputForm";
import { Alert, Card, Checkbox, Form, Select } from "antd";
import { checkOrariApertura, disabledDate } from "../Utils_Component/FormatDate";
import { Moment } from "moment";
import { FormSelectTime } from "../Utils_Component/FormSelect";
import { TranslateString } from "../../i18n/translate";
import moment from "moment";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { checkNotValidString } from "../Prese/PreseInterface";
import { PreventiviFormSoggetto } from "./PreventiviFormSoggetto";
var _ = require('lodash');
const { Option } = Select;

interface PreventiviFormProps {
    record: PresaPortDTO,
    guid: string,
    onChange: (record: PresaPortDTO) => void,
    isPortalUser: boolean
}


export const PreventiviForm: React.FC<PreventiviFormProps> = props => {
    const [rubricaOperatori, setRubricaOperatori] = React.useState<any[]>([]);
    const [holidays, setHolidays] = React.useState<TFestivi[]>([]);
    const isFormReadOnly = false;

    React.useEffect(
        () => {
            loadRubricaOperatori();
            getHolidays();
        }, []
    );



    const getHolidays = () => {
        RestCall.getAll(RestUrl.PREVENTIVI_GET_FESTIVI, 'url').then(
            result => {
                if (typeof result !== 'undefined' && typeof result !== 'string') {
                    setHolidays(result.data);
                } else if (typeof result === 'string') {
                    if (result === 'VALID_TOKEN') {
                        getHolidays();
                    } else {
                        notifyError(result);
                    }
                }
            }
        );
    };

    const loadRubricaOperatori = () => {
        if (props.isPortalUser) {
            RestCall.getAll(RestUrl.PREVENTIVI_OPERATORI + props.guid, 'url').then(
                result => {
                    if (typeof result !== 'undefined' && typeof result !== 'string') {
                        if (result.data.length > 0)
                            setRubricaOperatori(result.data);
                        else
                            handleChangesOperatore('Operatore Preventivi')
                    } else if (typeof result === 'string') {
                        if (result === 'VALID_TOKEN') {
                            loadRubricaOperatori();
                        } else {
                            notifyError(result);
                        }
                    }
                }
            );
        }
        else {
            handleChangesOperatore('Operatore Preventivi')
        }
    }

    const handleChanges = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {//gestisce i cambiamenti di stato che avvengono nelle textbox della form
        const target = event.target;
        const value = target.value;//contiene il nuovo valore della textbox
        const name = target.name;//contiene il nome del valore così potrà essere cambiato autonomamente, come si vede nel passaggio sottostante con [name]
        const newRecord = {
            ...props.record,
            [name]: value
        };

        //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
        props.onChange(newRecord);
    }


    const handleChangesDestinazione = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {//gestisce i cambiamenti di stato che avvengono nelle textbox della form
        const target = event.target;
        const value = target.value;//contiene il nuovo valore della textbox
        const name = target.name;//contiene il nome del valore così potrà essere cambiato autonomamente, come si vede nel passaggio sottostante con [name]
        const newDestinazione = {
            ...props.record.destinazioni[0],
            [name]: value
        };

        const newRecord = {
            ...props.record,
            destinazioni: [newDestinazione]
        }

        //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
        props.onChange(newRecord);
    }


    const handleDataChanges = (event: any, value?: any) => {
        const newDate = event !== null ? (event as Moment).format('YYYY-MM-DD[T]HH:mm:ss') : '';

        const newRecord = {
            ...props.record,
            dataRitiro: newDate
        };

        //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
        props.onChange(newRecord);
    }

    const handleChangesOperatore = (value: any) => {//gestisce i cambiamenti di stato che avvengono nelle textbox della form

        const newRecord = {
            ...props.record,
            operatorePortale: value
        };

        //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
        props.onChange(newRecord);

    }

    const handleChangesMittentePortDTO = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {//gestisce i cambiamenti di stato che avvengono nelle textbox della form
        const target = event.target;
        const value = target.value;//contiene il nuovo valore della textbox
        const name = target.name;//contiene il nome del valore così potrà essere cambiato autonomamente, come si vede nel passaggio sottostante con [name]
        const newRecord = {
            ...props.record,
            mittente: {
                ...props.record.mittente,
                [name]: value
            }
        };

        //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
        props.onChange(newRecord);
    }

    const onSearchMittente = (fieldName: string | undefined, timeString: string) => {
        if (fieldName !== undefined) {
            const newRecord = {
                ...props.record,
                mittente: {
                    ...props.record.mittente,
                    [fieldName]: timeString
                }
            };
            //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
            props.onChange(newRecord);
        }
    }

    const onSearchSelect = (fieldName: string | undefined, timeString: string) => {
        if (fieldName !== undefined) {
            const newRecord = {
                ...props.record,
                [fieldName]: timeString
            };
            props.onChange(newRecord);
        }
    }

    const handleCheckChanges = (evt: CheckboxChangeEvent) => {
        const name = evt.target.name;
        if (typeof name !== 'undefined') {
            let newRecord = {
                ...props.record
            }

            if (name === 'orarioContinuato') {
                newRecord = {
                    ...newRecord,
                    mittente:
                    {
                        ...newRecord.mittente,
                        orarioContinuato: evt.target.checked
                    }
                };

                if (evt.target.checked) {
                    newRecord = {
                        ...newRecord,
                        mittente: {
                            ...newRecord.mittente,
                            orarioApertura2: null,
                            orarioChiusura2: null
                        }
                    }
                }
            }
            else {
                newRecord = {
                    ...newRecord,
                    [name]: evt.target.checked
                };

                //se pronto devo svuotare l'eventuale ora di ritiro
                if (name === 'pronto' && evt.target.checked) {
                    newRecord = {
                        ...newRecord,
                        oraRitiro: ''
                    }
                }
            }


            //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
            props.onChange(newRecord);
        }
    }

    const checkPhoneValidateMittente = React.useMemo(() => {
        return _.isEmpty(props.record.mittente.telefono) || !checkStringOnlyNumbers(props.record.mittente.telefono) ? 'error' : undefined;
    }, [props.record.mittente.telefono]);

    const checkPhoneValidateDestinatario = React.useMemo(() => {
        return _.isEmpty(props.record.destinazioni[0].telefono) || !checkStringOnlyNumbers(props.record.destinazioni[0].telefono) ? 'error' : undefined;
    }, [props.record.destinazioni[0].telefono]);

    const checkDataRitiroValidate = React.useMemo(() => {
        return _.isEmpty(props.record.dataRitiro) || moment(props.record.dataRitiro) < moment().startOf('day') ? 'error' : undefined
    }, [props.record.dataRitiro]);

    const checkTimeValidate = React.useMemo(() => {
        const orario = moment(props.record.oraRitiro).format("HHmm");
        const validInterval = orario >= '0730' && orario <= '1630'
        return validInterval ? undefined : 'error';
    }, [props.record.oraRitiro]);


    const checkNoteClienteNoPortale = React.useMemo(() => {
        return checkNotValidString(props.record.noteClienteNoPortale)
    }, [props.record.noteClienteNoPortale]);



    return (
        <>
            <Form>
                <Card title="Dati generali" size="small" headStyle={{ backgroundColor: '#fafafa' }}>

                    {!props.isPortalUser &&
                        <>
                            {/*<FormTextInput_new
                                onChange={handleChanges}
                                label_name="NOTE_CLIENTE"
                                mandatory={true}
                                input_name={"noteClienteNoPortale"}
                                input_value={props.record.noteClienteNoPortale!}
                                validate={checkNoteClienteNoPortale}
                                readonly={isFormReadOnly}
                            />*/}
                            <PreventiviFormSoggetto onChange={props.onChange} record={props.record} />
                        </>

                    }
                    <div className="container-row">
                        <div className="column-1D2 container-col margin-right-5">
                            <div className="column-1">
                                <label className="label-font" htmlFor={'filterCustomer'}>Operatore</label>
                                <label className="label-font label-red" htmlFor={'filterCustomer_obbligatorio'}> *</label>
                            </div>
                            <Select
                                showSearch
                                placeholder={'Seleziona l\'operatore'}
                                optionFilterProp="children"
                                onChange={handleChangesOperatore}
                                value={props.record.operatorePortale!}
                                disabled={isFormReadOnly || rubricaOperatori.length === 0}
                            >
                                {
                                    rubricaOperatori.map(
                                        (item: any) =>
                                            <Option key={'opeartore_' + item.id} value={item.nome}>
                                                {item.nome}
                                            </Option>
                                    )
                                }
                            </Select>

                        </div>
                        <div className="column-1D2 container-col">
                            <FormTextInput_new
                                onChange={handleChanges}
                                label_name="RIFERIMENTO_FATTURAZIONE"
                                mandatory={false}
                                input_name={"riferimentoFatturazione"}
                                input_value={props.record.riferimentoFatturazione!}
                                validate={undefined}
                                readonly={isFormReadOnly}
                            />
                        </div>
                    </div>
                </Card>
                <Card
                    title="Mittente"
                    size="small"
                    className=""
                    headStyle={{ backgroundColor: '#fafafa' }}
                    style={{ marginTop: '20px' }}
                //extra={isFormReadOnly ? <></> : <><Button className="table-new-button" onClick={() => showDrawerRubricaMittenti(true)}><IdcardOutlined />{TranslateString("@RUBRICA")}</Button><Button className="table-new-button margin-right-5" onClick={getMyData}><HomeOutlined />{TranslateString("@RUBRICA_MY_DATA")}</Button></>}
                >
                    {/*<div className="container-row">
                     <div className="column-1D2 container-col margin-right-5">
                            <FormTextInput_new
                                onChange={handleChangesMittentePortDTO}
                                label_name="RAGSOC"
                                mandatory={true}
                                input_name={"ragioneSociale"}
                                input_value={props.record.mittente.ragioneSociale!}
                                validate={undefined}
                                //readonly={isFormReadOnly}
                                onKeyPress={handleSearchMittente}
                            />
                        </div>
                        <div className="column-1D2 container-col ">
                            <FormTextInput_new
                                onChange={handleChangesMittentePortDTO}
                                label_name="C/O"
                                mandatory={false}
                                input_name={"perContoDi"}
                                input_value={props.record.mittente.perContoDi!}
                                validate={undefined}
                                readonly={isFormReadOnly}
                            />
                        </div>
                    </div>
                    <div className="container-row">
                        <div className="column-1D2 container-col ">
                            <FormTextInput_new
                                onChange={handleChangesMittentePortDTO}
                                label_name="INDIRIZZO"
                                mandatory={true}
                                input_name={"indirizzo"}
                                input_value={props.record.mittente.indirizzo!}
                                validate={undefined}
                                readonly={isFormReadOnly}
                            />
                        </div>
                    </div>
                    <div className="container-row margin-bottom-15">
                        <div className="column-1D4 container-col margin-right-5">
                            <div className="column-1">
                                <label className="label-font" htmlFor={'filterCustomer'}>Provincia</label>
                                <label className="label-font label-red" htmlFor={'filterCustomer_obbligatorio'}> *</label>
                            </div>
                            <Select
                                showSearch
                                placeholder={'Seleziona...'}
                                optionFilterProp="children"
                                onChange={handleChangesProvincia}
                                value={props.record.mittente.provincia!}
                                allowClear
                            >
                                {
                                    listProvince.map(
                                        (item: any) =>
                                            <Option key={'provincia_' + item} value={item}>
                                                {item}
                                            </Option>
                                    )
                                }
                            </Select>
                        </div>
                        <div className="column-1D2 container-col margin-right-5">
                            <div className="column-1">
                                <label className="label-font" htmlFor={'filterCustomer'}>Località</label>
                            </div>
                            <Select
                                showSearch
                                placeholder={'Seleziona...'}
                                optionFilterProp="children"
                                onChange={handleChangesLocalita}
                                value={props.record.mittente.localita!}
                                allowClear
                            >
                                {
                                    listLocalita.map(
                                        (item: any) =>
                                            <Option key={'localita_' + item} value={item}>
                                                {item}
                                            </Option>
                                    )
                                }
                            </Select>
                        </div>
                        <div className="column-1D5 container-col margin-right-5">
                            <div className="column-1">
                                <label className="label-font" htmlFor={'filterCustomer'}>CAP</label>
                            </div>
                            <Select
                                showSearch
                                placeholder={'Seleziona...'}
                                optionFilterProp="children"
                                onChange={handleChangesCap}
                                value={props.record.mittente.cap!}
                                allowClear
                            >
                                {
                                    listCap.map(
                                        (item: any) =>
                                            <Option key={'cap_' + item} value={item}>
                                                {item}
                                            </Option>
                                    )
                                }
                            </Select>
                        </div>
                            </div>*/}
                    <div className="container-row">
                        <div className="column-1D2 container-col margin-right-5">
                            <FormTextInput_new
                                onChange={handleChangesMittentePortDTO}
                                label_name="TELEFONO"
                                mandatory={true}
                                input_name={"telefono"}
                                input_value={props.record.mittente.telefono!}
                                validate={checkPhoneValidateMittente}
                                helpMessage={TranslateString('@TELEFONO_VALIDATE_ERROR')}
                                readonly={isFormReadOnly}
                            />
                        </div>
                        <div className="column-1D2 container-col margin-right-5">
                            <FormTextInput_new
                                onChange={handleChangesMittentePortDTO}
                                label_name="RIFERIMENTO_RITIRO"
                                mandatory={false}
                                input_name={"riferimentoRitiro"}
                                input_value={props.record.mittente.riferimentoRitiro!}
                                validate={undefined}
                                readonly={isFormReadOnly}
                            />
                        </div>
                        <div className="column-1D2 container-col ">
                            <FormTextInput_new
                                onChange={handleChangesMittentePortDTO}
                                label_name="RIFERIMENTO"
                                mandatory={false}
                                input_name={"nome"}
                                input_value={props.record.mittente.nome!}
                                validate={undefined}
                                readonly={isFormReadOnly}
                            />
                        </div>
                    </div>
                    <div className="container-row">
                        <div className="column-1D2 container-col margin-right-5">
                            {/*                             <FormTimeInput
                                onTimeChange={handleTimeMittenteChanges}
                                label_name="ORA_MITTENTE_MATTINO_APERTURA"
                                mandatory={true}
                                input_name={"orarioApertura1"}
                                time_value={getTimeMittenteMattinoAperturaMemo}
                                validate={undefined}
                                readonly={isFormReadOnly}
                            /> */}
                            <FormSelectTime
                                record={props.record.mittente}
                                object_name={"orarioApertura1"}
                                label_name={"ORA_MITTENTE_MATTINO_APERTURA"}
                                validate={undefined}
                                mandatory={true}
                                onRecordChange={(e) => onSearchMittente('orarioApertura1', e.orarioApertura1)}
                                da={6}
                                a={19}
                            />
                        </div>
                        <div className="column-1D2 container-col margin-right-5">
                            {/*                             <FormTimeInput
                                onTimeChange={handleTimeMittenteChanges}
                                label_name="ORA_MITTENTE_MATTINO_CHIUSURA"
                                mandatory={true}
                                input_name={"orarioChiusura1"}
                                time_value={getTimeMittenteMattinoChiusuraMemo}
                                validate={undefined}
                                readonly={isFormReadOnly}
                            /> */}
                            <FormSelectTime
                                record={props.record.mittente}
                                object_name={"orarioChiusura1"}
                                label_name={"ORA_MITTENTE_MATTINO_CHIUSURA"}
                                validate={undefined}
                                mandatory={true}
                                onRecordChange={(e) => onSearchMittente('orarioChiusura1', e.orarioChiusura1)}
                                da={6}
                                a={19}
                            />
                        </div>
                        <div className="column-1D2 container-col margin-right-5">
                            {/*                             <FormTimeInput
                                onTimeChange={handleTimeMittenteChanges}
                                label_name="ORA_MITTENTE_POMERIGGIO_APERTURA"
                                mandatory={true}
                                input_name={"orarioApertura2"}
                                time_value={getTimeMittentePomeriggioAperturaMemo}
                                validate={undefined}
                                readonly={(isFormReadOnly || props.record.mittente.orarioContinuato) ? true : undefined}
                            /> */}
                            <FormSelectTime
                                record={props.record.mittente}
                                object_name={"orarioApertura2"}
                                label_name={"ORA_MITTENTE_POMERIGGIO_APERTURA"}
                                validate={undefined}
                                mandatory={true}
                                readOnly={(isFormReadOnly || props.record.mittente.orarioContinuato) ? true : undefined}
                                onRecordChange={(e) => onSearchMittente('orarioApertura2', e.orarioApertura2)}
                                da={6}
                                a={19}
                            />
                        </div>
                        <div className="column-1D2 container-col margin-right-5">
                            {/*                             <FormTimeInput
                                onTimeChange={handleTimeMittenteChanges}
                                label_name="ORA_MITTENTE_POMERIGGIO_CHIUSURA"
                                mandatory={true}
                                input_name={"orarioChiusura2"}
                                time_value={getTimeMittentePomeriggioChiusuraMemo}
                                validate={undefined}
                                readonly={(isFormReadOnly || props.record.mittente.orarioContinuato) ? true : undefined}
                            /> */}
                            <FormSelectTime
                                record={props.record.mittente}
                                object_name={"orarioChiusura2"}
                                label_name={"ORA_MITTENTE_POMERIGGIO_CHIUSURA"}
                                validate={undefined}
                                mandatory={true}
                                readOnly={(isFormReadOnly || props.record.mittente.orarioContinuato) ? true : undefined}
                                onRecordChange={(e) => onSearchMittente('orarioChiusura2', e.orarioChiusura2)}
                                da={6}
                                a={19}
                            />
                        </div>
                        <div className="column-1D2 container-col ">
                            <div className="column-1">
                                <label className="label-font" htmlFor={''}>&nbsp;</label>
                            </div>
                            <Checkbox checked={props.record.mittente.orarioContinuato} name="orarioContinuato" onChange={(evt) => handleCheckChanges(evt)} >
                                Continuato
                            </Checkbox>
                        </div>
                    </div>
                    {!checkOrariApertura(props.record.mittente.orarioApertura1, props.record.mittente.orarioChiusura1, props.record.mittente.orarioApertura2, props.record.mittente.orarioChiusura2, props.record.mittente.orarioContinuato) && <Alert message={TranslateString("@ERR_INSER_ORARI")} type="warning" showIcon style={{ marginBottom: 20 }} />}
                    <div className="container-row">
                        <div className="column-1D2 container-col margin-right-5">
                            <FormDataPickerInput
                                disabledDate={(date) => disabledDate(date, holidays)}
                                onChangeDataPicker={handleDataChanges}
                                label_name="DATA_RITIRO"
                                mandatory={true}
                                input_name={"dataRitiro"}
                                date_value={props.record.dataRitiro!}
                                validate={isFormReadOnly ? undefined : checkDataRitiroValidate}
                                readonly={isFormReadOnly}
                                helpMessage={'Data obbl. e futura'}
                            />
                        </div>
                        <div className="column-1D2 container-col margin-right-5">
                            {/*                             <FormTimeInput
                                onTimeChange={handleTimeChanges}
                                label_name="ORA_RITIRO"
                                mandatory={true}
                                input_name={"oraRitiro"}
                                time_value={getTimeMemo}
                                validate={isFormReadOnly || props.record.pronto ? undefined : checkTimeValidate}
                                readonly={isFormReadOnly || (props.record.pronto ? true : undefined)}
                                helpMessage={'Dalle 7:30 alle 16:30 (ogni 15\')'}
                            /> */}
                            <FormSelectTime
                                record={props.record}
                                object_name={"oraRitiro"}
                                label_name={"ORA_RITIRO"}
                                validate={isFormReadOnly || props.record.pronto ? undefined : checkTimeValidate}
                                readOnly={isFormReadOnly || (props.record.pronto ? true : undefined)}
                                onRecordChange={(e) => onSearchSelect('oraRitiro', e.oraRitiro)}
                                da={7}
                                a={16}
                                mandatory={true}
                                mezza={true}
                                helpMessage={'Dalle 7:30 alle 16:30 (ogni 15\')'}
                            />
                        </div>
                        {/*<div className="column-1D2 container-col ">
                            <div className="column-1">
                                <label className="label-font" htmlFor={''}>&nbsp;</label>
                            </div>
                            <Checkbox disabled={isFormReadOnly} checked={props.record.pronto} name="pronto" onChange={(evt) => handleCheckChanges(evt)} >
                                {TranslateString("@FLAG_PRONTO")}
                            </Checkbox>
                        </div>*/}
                    </div>
                </Card>
                <Card title="Altri dati" size="small" style={{ marginTop: '20px' }} headStyle={{ backgroundColor: '#fafafa' }}>
                    {/*<div className="container-row">
                        <div className="column-1D2 container-col margin-right-5">
                            <Checkbox disabled={isFormReadOnly} checked={props.record.flagMercePericolosa} name="flagMercePericolosa" onChange={(evt) => handleCheckChanges(evt)} >
                                {TranslateString("@FLAG_MERCEPERICOLOSA")}
                            </Checkbox>
                        </div>
                        <div className="column-1D2 container-col margin-right-5">
                            <Checkbox disabled={isFormReadOnly} checked={props.record.flagPreavvisoTelefonico} name="flagPreavvisoTelefonico" onChange={(evt) => handleCheckChanges(evt)} >
                                {TranslateString("@FLAG_PREAVVISOTELEFONICO")}
                            </Checkbox>
                        </div>
                        <div className="column-1D2 container-col ">
                            <Checkbox disabled={isFormReadOnly} checked={props.record.flagSpondaIdraulica} name="flagSpondaIdraulica" onChange={(evt) => handleCheckChanges(evt)} >
                                {TranslateString("@FLAG_SPONDAIDRAULICA")}
                            </Checkbox>
                        </div>
                        </div>*/}
                    <div className="container-row" style={{ marginTop: '10px' }}>
                        <div className="column-1D2 container-col">
                            <FormTextInput_new
                                onChange={handleChanges}
                                label_name="NOTE_RITIRO"
                                mandatory={false}
                                input_name={"noteRitiro"}
                                input_value={props.record.noteRitiro!}
                                validate={undefined}
                                readonly={isFormReadOnly}
                                maxLength={256}
                            />
                        </div>
                    </div>
                </Card>
                <Card
                    title="Destinazione"
                    size="small"
                    style={{ marginTop: '20px', marginBottom: '20px' }}
                    headStyle={{ backgroundColor: '#fafafa' }}
                //extra={isFormReadOnly ? <></> : <><Button className="table-new-button" onClick={() => showDrawerRubricaDestinatari(true)}><IdcardOutlined />{TranslateString("@RUBRICA")}</Button><Button className="table-new-button margin-right-5" onClick={getMyDataDestinatari}><HomeOutlined />{TranslateString("@RUBRICA_MY_DATA")}</Button></>}
                >
                    <div className="container-row">
                        <div className="column-1D2 container-col margin-right-5">
                            <FormTextInput_new
                                onChange={handleChangesDestinazione}
                                label_name="TELEFONO"
                                mandatory={true}
                                input_name={"telefono"}
                                input_value={props.record.destinazioni[0].telefono!}
                                validate={checkPhoneValidateDestinatario}
                                helpMessage={TranslateString('@TELEFONO_VALIDATE_ERROR')}
                                readonly={isFormReadOnly}
                            />
                        </div>
                        <div className="column-1D2 container-col margin-right-5">
                            <FormTextInput_new
                                onChange={handleChangesDestinazione}
                                label_name="PERSONARIFERIMENTO"
                                mandatory={false}
                                input_name={"personaRiferimento"}
                                input_value={props.record.destinazioni[0].personaRiferimento!}
                                validate={undefined}
                                readonly={isFormReadOnly}
                            />
                        </div>
                        {/*<div className="column-1D2 container-col margin-right-5" style={{ alignItems: 'center' }}>
                            <div className="column-1">
                                <label className="label-font" htmlFor={''}>&nbsp;</label>
                            </div>
                            <Checkbox disabled={isFormReadOnly} checked={props.destinazione.flagPreavvisoTelefonico} name="flagPreavvisoTelefonico" onChange={(evt) => handleCheckChanges(evt)} >
                                {TranslateString("@FLAG_PREAVVISOTELEFONICO")}
                            </Checkbox>
                        </div>
                        <div className="column-1D2 container-col margin-right-5" style={{ alignItems: 'center' }}>
                            <div className="column-1">
                                <label className="label-font" htmlFor={''}>&nbsp;</label>
                            </div>
                            <Checkbox disabled={isFormReadOnly} checked={props.destinazione.flagSpondaIdraulica} name="flagSpondaIdraulica" onChange={(evt) => handleCheckChanges(evt)} >
                                {TranslateString("@FLAG_SPONDAIDRAULICA")}
                            </Checkbox>
                    </div>*/}
                    </div>
                    <div className="container-row" style={{ marginTop: '10px' }} >
                        <div className="column-1D2 container-col">
                            <FormTextInput_new
                                onChange={handleChangesDestinazione}
                                label_name="NOTE_CONSEGNA"
                                mandatory={false}
                                input_name={"noteConsegna"}
                                input_value={props.record.destinazioni[0].noteConsegna!}
                                validate={undefined}
                                readonly={isFormReadOnly}
                                maxLength={256}
                            />
                        </div>
                    </div>
                </Card>
            </Form>
        </>
    )
}


