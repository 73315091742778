import type { ColumnsType } from 'antd/es/table';
import { ShippingTrackingStatusLineModel } from "../Model/ShippingTrackingModel";
import { Tag } from 'antd';
import { FormateDateTimeWith00 } from '../../utils/FormatDate';
import Text from 'antd/lib/typography/Text';
import { TranslateString } from '../../i18n/translate';
export const SearchShippingColumns = (): ColumnsType<ShippingTrackingStatusLineModel> => {
    return [
        {
            title: "",
            key: 'index',
            width: "50px",
            render: (_,_r,index) => index + 1
        },
        {
            title: <Text strong>{TranslateString("@DATASTATOBOLLA_LABEL_SEARCH_TRACKING")}</Text>,
            dataIndex: 'dataStatoBolla',
            key: 'dataStatoBolla',
            render: (data,record) => record.statoBolla === 100 ? (FormateDateTimeWith00(data).replace(', 00:00','')) : ''
        },
        {
            title: <Text strong  className="container-row c-ctr">{TranslateString("@STATOBOLLA_LABEL_SEARCH_TRACKING")}</Text>,
            dataIndex: 'statoBolla',
            key: 'statoBolla',
            render: (stato, record) => {

                if (stato === 4) {
                    //"Inserita"
                    return <div className="container-row c-ctr"><Tag color='red' key={'stato_' + stato}>{record.descrStato}</Tag></div>
                }
                if (stato === 10) {
                    //"Inserita"
                    return <div className="container-row c-ctr"><Tag color='yellow' key={'stato_' + stato}>{record.descrStato}</Tag></div>
                }
                if (stato === 11) {
                    //"Inserita"
                    return <div className="container-row c-ctr"><Tag color='yellow' key={'stato_' + stato}>{record.descrStato}</Tag></div>
                }
                if (stato === 20 || stato === 80) {
                    //"In Consegna"
                    return <div className="container-row c-ctr"><Tag color='lime' key={'stato_' + stato}>{record.descrStato}</Tag></div>
                }

                if (stato === 30) {
                    //"In Transito"
                    return <div className="container-row c-ctr"><Tag color='blue' key={'stato_' + stato}>{record.descrStato}</Tag></div>
                }

                if (stato === 40) {
                    //"Prenotata"
                    return <div className="container-row c-ctr"><Tag color='purple' key={'stato_' + stato}>{record.descrStato}</Tag></div>
                }

                if (stato === 50) {
                    //"In Giacenza"
                    return <div className="container-row c-ctr"><Tag color='orange' key={'stato_' + stato}>{record.descrStato}</Tag></div>
                }

                if (stato === 100) {
                    //"Consegnata"
                    return <div className="container-row c-ctr"><Tag color='green' key={'stato_' + stato}>{record.descrStato}</Tag></div>
                }
            }
        }
    ];
}