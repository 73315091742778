import React from "react";
import { PresaPortDTO } from "../Model/PreseModel"
import { RestCall } from "../Rest/Rest_Call/RestCall";
import { notifyError } from "../CustomToast/customToast";
import { ProvLocCapDTO } from "../Model/RubricaModel";
import RestUrl from '../../RestUrl.json';
import { FormTextInput_new } from "../Utils_Component/InputForm";
import { Divider, Select } from "antd";
const { Option } = Select;

interface PreventiviFormSoggettoProps {
    record: PresaPortDTO,
    onChange: (record: PresaPortDTO) => void
}


export const PreventiviFormSoggetto: React.FC<PreventiviFormSoggettoProps> = props => {
    const [listProvince, setListProvince] = React.useState<any[]>([]);
    const [listCap, setListCap] = React.useState<any[]>([]);
    const [listLocalita, setListLocalita] = React.useState<any[]>([]);
    const [listNazioni, setListNazioni] = React.useState<any[]>([]);

    const isFormReadOnly = false;

    React.useEffect(
        () => {
            loadListaNazioni();
            reloadListaIndirizzi();
        }, []
    );

    React.useEffect(
        () => {
            reloadListaIndirizzi();
        }, [props.record.soggettoPreventivo.CAP, props.record.soggettoPreventivo.Localita, props.record.soggettoPreventivo.Provincia]
    );

    const reloadListaIndirizzi = () => {
        loadListaProvince();
        loadListaCap();
        loadListaLocalita();
    }


    const loadListaNazioni = () => {
        RestCall.getAll(RestUrl.RUBRICA_NATIONS, 'url').then(
            result => {
                if (typeof result !== 'undefined' && typeof result !== 'string') {
                    setListNazioni(result.data);
                } else if (typeof result === 'string') {
                    if (result === 'VALID_TOKEN') {
                        loadListaNazioni();
                    } else {
                        notifyError(result);
                    }
                }
            }
        );
    }

    const loadListaProvince = () => {
        const payload: ProvLocCapDTO = {
            provincia: null,//props.record.provincia,
            localita: props.record.soggettoPreventivo.Localita,
            cap: props.record.soggettoPreventivo.CAP
        }

        RestCall.post_with_result_no_pagination(payload, RestUrl.RUBRICA_PROVINCE_NEW, 'url').then(
            result => {
                if (typeof result !== 'undefined' && typeof result !== 'string') {
                    setListProvince(result.data.data);
                } else if (typeof result === 'string') {
                    if (result === 'VALID_TOKEN') {
                        loadListaProvince();
                    } else {
                        notifyError(result);
                    }
                }
            }
        );
    }

    const loadListaCap = () => {
        const payload: ProvLocCapDTO = {
            provincia: props.record.soggettoPreventivo.Provincia,
            localita: props.record.soggettoPreventivo.Localita,
            cap: null,//props.record.cap
        }

        RestCall.post_with_result_no_pagination(payload, RestUrl.RUBRICA_CAPS_NEW, 'url').then(
            result => {
                if (typeof result !== 'undefined' && typeof result !== 'string') {
                    setListCap(result.data.data);
                } else if (typeof result === 'string') {
                    if (result === 'VALID_TOKEN') {
                        loadListaCap();
                    } else {
                        notifyError(result);
                    }
                }
            }
        );
    }

    const loadListaLocalita = () => {
        const payload: ProvLocCapDTO = {
            provincia: props.record.soggettoPreventivo.Provincia,
            localita: null,//props.record.localita,
            cap: props.record.soggettoPreventivo.CAP
        }
        RestCall.post_with_result_no_pagination(payload, RestUrl.RUBRICA_LOCALITA_NEW, 'url').then(
            result => {
                if (typeof result !== 'undefined' && typeof result !== 'string') {
                    setListLocalita(result.data.data);
                } else if (typeof result === 'string') {
                    if (result === 'VALID_TOKEN') {
                        loadListaLocalita();
                    } else {
                        notifyError(result);
                    }
                }
            }
        );

    }


    const handleChangesSoggettoPreventivo = (value: any, field: string) => {
        const newRecord = {
            ...props.record,
            soggettoPreventivo: {
                ...props.record.soggettoPreventivo,
                [field]: value
            }
        };
        props.onChange(newRecord);
    }

    const handleChangesNazioneDestinazione = (value: any) => {//gestisce i cambiamenti di stato che avvengono nelle textbox della form
        const naz = listNazioni.find((item) => item.codice === value);

        handleChangesSoggettoPreventivo(naz.codice, 'CodiceNazione')

    }

    return <>
        <div style={{ marginBottom: 10}}>
            <Divider>Dati soggetto</Divider>
            <div className="container-row">
                <div className="column-1D2  margin-right-5">
                    <FormTextInput_new
                        onChange={(event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => handleChangesSoggettoPreventivo(event.target.value, event.target.name)}
                        label_name="RAGSOC"
                        mandatory={true}
                        input_name={"RagioneSociale"}
                        input_value={props.record.soggettoPreventivo.RagioneSociale!}
                        validate={undefined}
                        readonly={isFormReadOnly}
                    />
                </div>
                <div className="column-1D2" >
                    <FormTextInput_new
                        onChange={(event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => handleChangesSoggettoPreventivo(event.target.value, event.target.name)}
                        label_name="INDIRIZZO"
                        mandatory={true}
                        input_name={"Indirizzo"}
                        input_value={props.record.soggettoPreventivo.Indirizzo!}
                        validate={undefined}
                        readonly={isFormReadOnly}
                    />
                </div>
            </div>
            <div className="container-row">
                <div className="column-1D4  margin-right-5">
                    <div className="container-column" style={{ width: '100%' }}>
                        <div className="label-font" >Provincia</div>
                        <Select
                            showSearch
                            placeholder={'Seleziona...'}
                            optionFilterProp="children"
                            onChange={(value: string) => handleChangesSoggettoPreventivo(value, 'Provincia')}
                            value={props.record.soggettoPreventivo.Provincia!}
                            allowClear
                            style={{ width: '100%' }}
                        >
                            {
                                listProvince.map(
                                    (item: any) =>
                                        <Option key={'provincia_' + item} value={item}>
                                            {item}
                                        </Option>
                                )
                            }
                        </Select>
                    </div>
                </div>
                <div className="column-1D4  margin-right-5">
                    <div className="container-column" style={{ width: '100%' }}>
                        <div className="label-font" >Località</div>
                        <Select
                            showSearch
                            placeholder={'Seleziona...'}
                            optionFilterProp="children"
                            onChange={(value: string) => handleChangesSoggettoPreventivo(value, 'Localita')}
                            allowClear
                            style={{ width: '100%' }}
                            value={props.record.soggettoPreventivo.Localita!}
                        >
                            {
                                listLocalita.map(
                                    (item: any) =>
                                        <Option key={'localita_' + item} value={item}>
                                            {item}
                                        </Option>
                                )
                            }
                        </Select>
                    </div>
                </div>


                <div className="column-1D4  margin-right-5">
                    <div className="container-column" style={{ width: '100%' }}>
                        <div className="label-font" >CAP</div>
                        <Select
                            showSearch
                            placeholder={'Seleziona...'}
                            optionFilterProp="children"
                            onChange={(value: string) => handleChangesSoggettoPreventivo(value, 'CAP')}
                            value={props.record.soggettoPreventivo.CAP!}
                            allowClear
                            style={{ width: '100%' }}
                        >
                            {
                                listCap.map(
                                    (item: any) =>
                                        <Option key={'cap_' + item} value={item}>
                                            {item}
                                        </Option>
                                )
                            }
                        </Select>
                    </div>
                </div>
                <div className="column-1D4  ">
                    <div className="container-column" style={{ width: '100%' }}>
                        <div className="label-font" >Nazione</div>
                        <Select
                            showSearch
                            placeholder={'Seleziona...'}
                            optionFilterProp="children"
                            onChange={handleChangesNazioneDestinazione}
                            value={props.record.soggettoPreventivo.CodiceNazione!}
                            style={{ width: '100%' }}
                        >
                            {
                                listNazioni.map(
                                    (item: any) =>
                                        <Option key={'nazione_' + item.codice} value={item.codice}>
                                            {item.codice} - {item.descrizione}
                                        </Option>
                                )
                            }
                        </Select>
                    </div>
                </div>
            </div>

            <div className="container-row">
                <div className="column-1D2  margin-right-5">
                    <FormTextInput_new
                        onChange={(event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => handleChangesSoggettoPreventivo(event.target.value, event.target.name)}
                        label_name="NOME"
                        mandatory={true}
                        input_name={"Nome"}
                        input_value={props.record.soggettoPreventivo.Nome!}
                        validate={undefined}
                        readonly={isFormReadOnly}
                    />
                </div>
                <div className="column-1D2  margin-right-5">
                    <FormTextInput_new
                        onChange={(event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => handleChangesSoggettoPreventivo(event.target.value, event.target.name)}
                        label_name="PHONENUMBER"
                        mandatory={true}
                        input_name={"Telefono"}
                        input_value={props.record.soggettoPreventivo.Telefono!}
                        validate={undefined}
                        readonly={isFormReadOnly}
                    />
                </div>
            </div>
            <div className="container-row">
                <div className="column-1D2  margin-right-5">
                    <FormTextInput_new
                        onChange={(event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => handleChangesSoggettoPreventivo(event.target.value, event.target.name)}
                        label_name="CF"
                        mandatory={true}
                        input_name={"CodiceFiscale"}
                        input_value={props.record.soggettoPreventivo.CodiceFiscale!}
                        validate={undefined}
                        readonly={isFormReadOnly}
                    />
                </div>
                <div className="column-1D2  ">
                    <FormTextInput_new
                        onChange={(event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => handleChangesSoggettoPreventivo(event.target.value, event.target.name)}
                        label_name="PIVA"
                        mandatory={true}
                        input_name={"PartitaIva"}
                        input_value={props.record.soggettoPreventivo.PartitaIva!}
                        validate={undefined}
                        readonly={isFormReadOnly}
                    />
                </div>
            </div>
            <Divider />
        </div>

    </>
}
